/* eslint-disable no-param-reassign, no-nested-ternary */
export const validateField = (
  component,
  form,
  fieldName,
  normalizer,
) => event => {
  // Name
  fieldName = fieldName || event.target.name;
  form.checkFieldExists(fieldName);

  // Type
  let fieldType =
    event.target.tagName === 'SELECT'
      ? event.target.multiple
        ? 'select.multiple'
        : 'select'
      : undefined;
  if (!fieldType) fieldType = event.target.type || 'text';

  // Value
  let { value } = event.target;

  if (
    (fieldType === 'number' || fieldType === 'range') &&
    value !== '' &&
    +value !== 'NaN'
  )
    value = +value;
  // Convert as an integer if needed
  else if (fieldType === 'select.multiple')
    value = Array.from(event.target.selectedOptions).map(opt => opt.value); // As array if multiple values

  const normalizedValue = normalizer ? normalizer(value) : value;
  event.target.value = normalizedValue;

  const newValue = form.saveField(fieldName, normalizedValue);
  // Check and forceUpdate
  form.validateField(fieldName, newValue, () => component.forceUpdate());
};

export { default as Form } from './form';
export { default as Validators } from './validators';
