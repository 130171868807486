import fetchival from 'fetchival';
import Cookies from 'js-cookie';
import psl from 'psl';
import { signInPayloadParser } from './signInParsers';

export default apiConfig => {
  return {
    fetch: fetchival(apiConfig.root, {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${Cookies.get(apiConfig.token)}`,
      },
    }),

    fetchWithoutAuth: fetchival(apiConfig.root, {
      credentials: 'include',
    }),

    /**
     * Iniciar sesion
     */
    signIn({ email, password, brand, recaptchaToken }, signInType) {
      const payload = signInPayloadParser(
        { email, password, brand, recaptchaToken },
        signInType,
      );
      return this.fetch(apiConfig.signIn).post(payload);
    },

    /**
     * Crear cuenta
     */
    signUp({
      firstName,
      lastName,
      birthday,
      gender,
      mobile,
      phone,
      email,
      emailConfirmation,
      id,
      provider,
    }) {
      // Assign endpoint based on provider and id
      // If provider and id then it means is an Third Party signup
      const endpoint = provider && id ? apiConfig.signInSP : apiConfig.signUp;

      return this.fetch(endpoint).post({
        ...(provider && id && { provider, id }),
        first_name: firstName,
        last_name: lastName,
        birthday,
        gender,
        mobile,
        phone,
        email,
        email_confirmation: emailConfirmation,
      });
    },

    /**
     * Cierra sesión
     */
    signOut({ logoutApi }) {
      const domain = psl.parse(window.location.hostname).domain;
      if (logoutApi === 'true') {
        return this.fetch(apiConfig.signOut)
          .delete()
          .then(() => {
            return new Promise(resolve => {
              Cookies.remove(apiConfig.tokenName, { domain });

              resolve(true);
            });
          });
      }

      return new Promise(resolve => {
        Cookies.remove(apiConfig.tokenName, { domain });

        resolve(true);
      });
    },

    /**
     * Obtiene el perfil del usuario
     */
    getProfile() {
      const token = Cookies.get(apiConfig.tokenName);
      const header = `Bearer ${token}`;

      return fetchival(`${apiConfig.root}/${apiConfig.getProfile}`, {
        credentials: 'include',
        headers: {
          Authorization: header,
        },
      }).get();
    },

    /**
     * Recuperar pin
     */
    forgotPin(email) {
      return this.fetchWithoutAuth(apiConfig.forgotPin).post({ email });
    },
  };
};
