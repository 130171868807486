import { h } from 'preact';
import { useText } from 'preact-i18n';

const DotersProfile = ({
  name,
  lastName,
  userId,
  level,
  levelIcon,
  points,
}) => {
  const { member_number, availablePointsLabel, you_are_level } = useText({
    member_number: 'label.member_number',
    you_are_level: 'label.you_are_level',
    availablePointsLabel: 'label.doters_points_available',
  });
  return (
    <div>
      <div className="doters-profile">
        <p className="text-xxl">
          <b>
            {name} {lastName}
          </b>
        </p>
        <p className="text-l">
          <b>
            {member_number}: {userId}
          </b>
        </p>
      </div>
      <div className="doters-profile">
        <p className="text-l">
          {you_are_level} <b>{level}</b> <i className={`doters-${levelIcon}`} />
        </p>
      </div>
      <div className="doters-points">
        <div>
          <p className="text-xxxl text-center">
            <i className={`doters-${levelIcon}`} /> <b>{points}</b>
          </p>
          <p className="text-m text-center">{availablePointsLabel}</p>
        </div>
      </div>
    </div>
  );
};

export default DotersProfile;
