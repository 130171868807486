import { Fragment, h } from 'preact';
import { useText } from 'preact-i18n';
import MenuItem from './MenuItem';
import LoadingPulse from './LoadingPulse';
import copies from '../../config/copies';
import DotersProfile from './DotersProfile';
import capitalize from '../utils/capitalize';

const ProfileContent = ({
  loggedIn,
  user,
  signOut,
  signInClick,
  signUpClick,
  isLoading,
  widgetProps,
}) => {
  const {
    doters_gold_level,
    doters_silver_level,
    doters_green_level,
    join_to_doters,
    sign_off,
    log_in,
    join_free,
    the_program,
    know_more_about_the_program,
    win_and_use,
    levels_and_benefits,
    my_profile,
    frequently_questions,
    click_here,
  } = useText({
    doters_gold_level: 'label.doters_gold_level',
    doters_silver_level: 'label.doters_silver_level',
    doters_green_level: 'label.doters_green_level',
    join_to_doters: 'label.join_to_doters',
    sign_off: 'link.sign_off',
    sign_up: 'button.sign_off',
    log_in: 'button.log_in_doters',
    join_free: 'link.join_free',
    the_program: 'text.the_program',
    know_more_about_the_program: 'text.know_more_about_the_program',
    win_and_use: 'text.win_and_use',
    levels_and_benefits: 'text.levels_and_benefits',
    my_profile: 'text.my_profile',
    frequently_questions: 'text.frequently_questions',
    click_here: 'text.click_here',
  });

  const levelTypes = {
    Gold: {
      label: doters_gold_level,
      icon: 'premium',
    },
    Silver: {
      label: doters_silver_level,
      icon: 'elite',
    },
    Green: {
      label: doters_green_level,
      icon: 'basic',
    },
  };

  const userLevel = (loggedIn && levelTypes[user.level]) || levelTypes.Green;

  let { doters: dotersCopies } = copies[
    widgetProps?.sandbox ? 'development' : 'production'
  ];

  const overrideExistingLinks = existingLinks => {
    let returnLinks = {};
    try {
      Object.keys(existingLinks).forEach(link => {
        const propName = `link${capitalize(link)}`;
        const { [propName]: linkFromProps } = widgetProps;

        if (!linkFromProps) {
          return undefined;
        }
        returnLinks = {
          ...returnLinks,
          [link]: linkFromProps,
        };
      });

      return returnLinks;
    } catch (error) {
      console.error("Failed to retrieve props's links", error);
      return returnLinks;
    }
  };

  // Override doters copies with data props
  dotersCopies = {
    links: {
      ...dotersCopies.links,
      ...overrideExistingLinks(dotersCopies.links),
    },
  };

  const footerContent = () => {
    if (isLoading) {
      return (
        <div className="profile-footer-loading">
          <LoadingPulse />
        </div>
      );
    }
    return loggedIn ? (
      <button onClick={signOut} className="profile-outline">
        {sign_off}
      </button>
    ) : (
      <div className="profile-footer-sign-container">
        <button onClick={signInClick} className="profile-outline">
          {log_in}
        </button>
        <button onClick={signUpClick} className="profile-button">
          {join_free}
        </button>
      </div>
    );
  };

  return (
    <div>
      {loggedIn ? (
        <div>
          <DotersProfile
            name={user.first_name}
            lastName={user.last_name}
            userId={user.doters_id}
            level={userLevel.label}
            levelIcon={userLevel.icon}
            points={user.available_points
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
          <div className="profile-menu-items">
            {user.links ? (
              user.links.map(link => (
                <MenuItem key={link.title} text={link.title} href={link.url} />
              ))
            ) : (
              <Fragment>
                <MenuItem
                  text={my_profile}
                  href={dotersCopies.links.myProfile}
                />
                <MenuItem
                  text={the_program}
                  href={dotersCopies.links.theProgram}
                />
                <MenuItem
                  text={win_and_use}
                  href={dotersCopies.links.winAndUse}
                />
                <MenuItem
                  text={levels_and_benefits}
                  href={dotersCopies.links.levelsAndBenefits}
                />
                <MenuItem
                  text={frequently_questions}
                  href={dotersCopies.links.frequentlyQuestions}
                />
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <div>
          <p className="text-xxl">
            <b>{join_to_doters}</b>
          </p>
          <br />
        </div>
      )}
      <div className="profile-footer">{footerContent()}</div>
      {!loggedIn && (
        <p className="profile-link">
          {know_more_about_the_program}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={dotersCopies.links.theProgram}
          >
            {click_here}
          </a>
        </p>
      )}
    </div>
  );
};

export default ProfileContent;
