import { Component, h } from 'preact';
import Modal from './generics/Modal';
import modalContent from '../constants/modalContent';
import SignInContent from './SignInContent';
import SignUpContent from './SignUpContent';
import ForgotPinContent from './ForgotPinContent';
import EmailSentContent from './EmailSentContent';

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalContent: modalContent.SIGN_IN,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      modalContent: this.props.modalContent || modalContent.SIGN_IN,
    });
  }

  getModalElements() {
    const {
      session,
      mixpanelTracker,
      thirdParty,
      widgetProps,
      user,
    } = this.props;
    const { modalContent: currentModalContent } = this.state;

    const props = {
      onChangeContent: newModalContent =>
        this.setState({ modalContent: newModalContent }),
      session,
      mixpanelTracker,
      thirdParty,
      widgetProps,
      user,
    };

    switch (currentModalContent) {
      case modalContent.SIGN_IN:
        return {
          title: widgetProps.signInTitle,
          content: <SignInContent {...props} />,
        };
      case modalContent.SIGN_UP:
        return {
          title: widgetProps.signUpTitle,
          content: <SignUpContent {...props} />,
        };
      case modalContent.FORGOT_PIN:
        return {
          title: widgetProps.recoverTitle,
          content: <ForgotPinContent {...props} />,
        };
      case modalContent.EMAIL_SENT:
        return {
          title: widgetProps.emailSentTitle,
          content: <EmailSentContent {...props} />,
        };
      case modalContent.EXTRA_DATA:
        return {
          title: widgetProps.extraDataTitle,
          content: <SignUpContent {...props} showOnlyConfirmationFields />,
        };
      default:
        return null;
    }
  }

  render({ modalDescription, modalIcon, onClose }) {
    const elements = this.getModalElements();

    return (
      <Modal
        title={elements.title}
        description={modalDescription}
        onUserClose={onClose}
        modalIcon={modalIcon}
      >
        {elements.content}
      </Modal>
    );
  }
}

export default SignInModal;
