import { h } from 'preact';
import { useState, useEffect, useCallback } from 'preact/hooks';
import { Text } from 'preact-i18n';
import Cookies from 'js-cookie';

const getWalletToken = tokenName => Cookies.get(tokenName);

const createURLWithToken = (token, accountUrl) => {
  const baseUrl = accountUrl;
  return token ? `${baseUrl}?token=${token}` : baseUrl;
};

const CostapassUserInfo = ({
  user,
  signOut,
  modifierClass,
  loyalty,
  accountUrl,
  tokenName,
  availableWallets,
}) => {
  const [walletUrlWithToken, setWalletUrlWithToken] = useState('');
  const { brand: loggedBrand } = user;

  const brandsArray = (() => {
    try {
      return JSON.parse(availableWallets) || [];
    } catch (error) {
      return [];
    }
  })();

  const brandUrls =
    brandsArray.find(({ brand }) => brand === loggedBrand)?.urls || null;

  const updateWalletUrl = useCallback(() => {
    const token = getWalletToken(tokenName);
    const effectiveAccountUrl = brandUrls?.account || accountUrl;
    setWalletUrlWithToken(createURLWithToken(token, effectiveAccountUrl));
  }, [accountUrl, tokenName, brandUrls]);

  useEffect(() => {
    updateWalletUrl();
  }, [updateWalletUrl]);

  return (
    <div className={`user-info ${modifierClass}`}>
      <p className="user-info-title">
        <i className={`badge-${loyalty}`} />
        <span>
          <Text id="text.hello" />
        </span>
        <b>{user.name}</b>
      </p>
      <div className="tooltip">
        <div className="user-nav">
          <div className="user-nav-row">
            <a
              className="user-nav-item"
              target="_blank"
              rel="noreferrer"
              href={walletUrlWithToken}
            >
              <Text id="link.my_account" />
            </a>
          </div>
          <div className="user-nav-row">
            <a className="user-nav-item" onClick={signOut}>
              <Text id="link.sign_off" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostapassUserInfo;
